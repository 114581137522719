import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class NoteService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/note';
    }

    // all() {
    //     let url = `${this.#api}/get/all`;
    //     return apiService.get(url)
    // }

    getAllUserNote(id) {
        let url = `${this.#api}/get/all/account-holder-notes/${id}`;
        return apiService.get(url)
    }

    getCandidateNote(id) {
        let url = `${this.#api}/get/all/candidate-notes/${id}`;
        return apiService.get(url)
    }
    getAllExamDayNote(examDayId){

        let url = `${this.#api}/get/all/exam-days/${examDayId}`;
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url,data)
    }
    noteMarked(id,data) {
        let url = `${this.#api}/marked/${id}`
        return apiService.post(url, data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}