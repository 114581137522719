<template>
  <v-dialog v-model="dialog"
      scrollable
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{title}} Note</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="note.name"
                  :error="$v.note.name.$error"
                  outlined
                  dense
              >
                <template v-slot:label>Name <span class="text-danger">*</span> </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >**{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.note.name.$error" >This information is required</span>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                  v-model="note.priority"
                  :error="$v.note.priority.$error"
                  :items="priorities"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>Priority <span class="text-danger">*</span> </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="note.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.note.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="4">
              Requires follow up
              <v-switch
                  @change="changeRequiredFollowup"
                  v-model="note.requires_follow_up"
                  :label="note.requires_follow_up ? 'Yes' : 'No'"
              ></v-switch>
            </v-col>

<!--            <v-col cols="12" md="4">-->
<!--              Is Admin Only-->
<!--              <v-switch-->
<!--                  v-model="note.is_admin_only"-->
<!--                  :label="note.is_admin_only ? 'Yes' : 'No'"-->
<!--              ></v-switch>-->
<!--            </v-col>-->

            <v-col cols="12" md="4">
              Status <span class="text-danger">*</span>
              <v-switch
                  v-model="note.is_active"
                  :label="note.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>

            <v-col cols="12" v-if="note.requires_follow_up">
              <strong>Follow Up</strong>
              <v-row class="mt-2">
                <v-col cols="12" md="12">
                  <v-menu
                      ref="menu"
                      v-model="menuFollowUpDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="note.followup_required_date"
                          :error="$v.note.followup_required_date.$error"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      >
                        <template v-slot:label>Date to follow up <span class="text-danger">*</span> </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="note.followup_required_date"
                        outlined
                        dense
                        no-title
                        @input="menuFollowUpDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="note.attachments.length <= 2">
              <strong>Attachments</strong>
              <div class="mt-2" v-if="note.note_attachments && note.note_attachments.length > 0">
                <span class="mx-2 font-size-lg" v-for="(attachment, index) in note.note_attachments" :key="index">
                    <a v-if="attachment && attachment.file_path" :href="attachment.file_path.thumb" target="_blank"><v-icon class="mx-1">fas fa-file-alt</v-icon>File</a>
                    <a v-else :href="attachment.url" target="_blank"><v-icon class="mx-1">fas fa-link</v-icon>Link</a>
                </span>
              </div>
              <v-row class="mt-3">
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Name"
                      v-model="attachmentName"
                      :error="$v.attachmentName.$error"
                      outlined
                      dense
                  ></v-text-field>
                  <span class="text-danger" v-if="$v.attachmentName.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                      label="Type"
                      v-model="type"
                      :error="$v.type.$error"
                      :items="types"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                  ></v-select>
                  <span class="text-danger" v-if="$v.type.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                      class="btn btn-primary text-white"
                      @click="addField()"
                  >
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" v-if="note.attachments.length > 0">
              <v-row v-for="(attachment, index) in note.attachments" :key="index">
                <v-col cols="12" md="5">
                  <v-text-field
                      label="Attachment name"
                      v-model="attachment.attachmentName"
                      disabled
                      readonly
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="edit && attachment.url">
                  <v-text-field
                      label="URL"
                      v-model="attachment.url"
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="attachment.type == 'url'">
                  <v-text-field
                      v-model="attachment.url"
                      :error="$v.note.attachments.$each[index].url.$error"
                      outlined
                      dense
                  >
                    <template v-slot:label>URL <span class="text-danger">*</span> </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.note.attachments.$each[index].url.$error">This information is required</span>
                  <span class="text-danger" v-if="$v.note.attachments.$each[index].url.url.$error">URL Should be Valid</span>
                </v-col>

                <v-col cols="12" md="6"  v-if="edit && attachment && attachment.file_path">
                  <span>
                    <a class="mt-4 font-size-lg" :href="attachment.file_path.thumb" target="_blank"><v-icon class="mx-1" >fas fa-link</v-icon>File</a>
                  </span>
                </v-col>

                <v-col cols="12" md="6" v-if="attachment.type == 'file'">

                  <v-file-input
                      label="Attachment file *"
                      v-model="attachment.file"
                      :error="$v.note.attachments.$each[index].file.$error"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      outlined
                      dense
                  >
                  </v-file-input>
                  <span class="text-danger" v-if="$v.note.attachments.$each[index].file.$error">This information is required</span>
                </v-col>

                <v-col cols="12" md="1">
                  <i class="mt-3 cursor-pointer fas fa-trash-alt"  @click="deleteAttachment(index)"></i>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            x-large
            @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            x-large
            @click="createOrUpdate()"
            dark
        >
          {{ edit ? 'Update' : 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
import NoteService from "@/services/note/NoteService";

const note=new NoteService();
export default {
  name: "AddNotes",
  data(){
    return{
      title: '',
      attachmentName: null,
      type: null,
      errors: [],
      edit: false,
      dialog: false,
      loading: false,
      menuFollowUpDate: false,

      priorities: [
        { name: 'Normal', value: 'normal' },
        { name: 'Low', value: 'low' },
        { name: 'Medium', value: 'medium' },
        { name: 'High', value: 'high' },
        { name: 'Urgent', value: 'urgent' },
      ],
      types: [
        { name: 'URL', value: 'url' },
        { name: 'File', value: 'file' },
      ],
      note: {
        name: '',
        description: '',
        priority: 'normal',
        is_admin_only: false,
        requires_follow_up: false,
        followup_required_date: '',
        is_active: true,
        attachments: [],
        remarks: ''
      },
      editorConfig: {
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'],
        ],
        versionCheck: false
      }
    }
  },
  validations: {
    attachmentName: { required },
    type: { required },
    note: {
      name: { required },
      description: { required },
      priority: { required },
      attachments: {
        $each : {
          file: {
            required: requiredIf(attachments => attachments.url == null )
          },
          url: {
            url,
            required: requiredIf(attachments => attachments.file == null )
          },
        }
      },
      followup_required_date: { required: requiredIf(function () {
          return this.note.requires_follow_up;
        })
      },
    }
  },
  methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog(examDayId) {
      this.note.exam_day_id=examDayId;
      this.$v.note.$reset();
      this.dialog = true;
    },
    addNotes(id) {
      this.title = 'Add';
      this.edit = false;
      this.openDialog();

    },
    editNote(note) {

      this.title = 'Edit';
      this.edit = true;
      this.openDialog(note.exam_day_id);
      this.note = Object.assign(this.note, note);
    },
    changeRequiredFollowup() {
      if(!this.note.requires_follow_up) {
        this.note.followup_required_date = '';
      }
    },
    addField() {
      this.$v.attachmentName.$touch();
      this.$v.type.$touch();
      if(this.$v.attachmentName.$error && this.$v.type.$error){
        setTimeout(() => {
          this.$v.attachmentName.$reset();
          this.$v.type.$reset();
        }, 3000);
      } else {
        if (this.attachmentName != null && this.type != null) {
          this.note.attachments.push({
            attachmentName: this.attachmentName,
            type: this.type,
            file: null,
            url: null,
          });
          this.attachmentName = null;
          this.type = null;
          this.$v.attachmentName.$reset();
          this.$v.type.$reset();
        }
      }
    },
    deleteAttachment(index) {
      this.note.attachments.splice(index, 1);
    },

    convertToFormData() {
      let formData = new FormData();

      for (let key in this.note) {
        if (key === "attachments" && this.note[key] != null && this.note[key] != undefined) {
          for (let i = 0; i < this.note[key].length; i++) {
            formData.append('names[]', this.note[key][i].attachmentName);
            if(this.note[key][i].file != null) {
              formData.append(`files[${i}]`, this.note[key][i].file);
            }else {
              formData.append(`urls[${i}]`, this.note[key][i].url);
            }
          }
        }else {
          if(this.note[key]){
            formData.append(key,this.note[key]);
          }
        }
      }
      return formData;
    },

    createOrUpdate() {
      this.$v.note.$touch()
      if (this.$v.note.$error) {
        setTimeout(() => {
          this.$v.note.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if(!this.edit) this._create();
        else this.update();
      }
    },
    _create() {
      let form = this.convertToFormData();
      note
          .create(form)
          .then(response => {
            this.$snotify.success("Note added");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');

          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      let form = this.convertToFormData();
      note
          .update(this.note.id, form)
          .then(response => {
            this.$emit('refresh');
            this.$snotify.success("Note updated");
            this.resetForm();
            this.closeDialog();
            this.loading = false;
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.attachmentName = null;
      this.type = null;
      this.note = {
        name: '',
        description: '',
        priority: 'normal',
        is_admin_only: false,
        requires_follow_up: false,
        followup_required_date: '',
        is_active: true,
        attachments: [],
        remarks: ''
      };
    },
  }

}
</script>