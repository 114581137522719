<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h5>
                  Schedule notes
                </h5>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{examDayDetail.name}} \ Schedule notes
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" v-if="notes.length > 0">
              <div class="col-md-12 col-lg-12 col-xl-6" v-if="notes.length> 0" v-for="(note, index) in notes"
                   :key="index">
                <div class="card border card-custom gutter-b">

                  <div class="card-body pt-7">
                    <div class="d-flex">
                      <div class="row">
                        <div class="col-md-3 text-left mb-2">
                          <h6>{{ note.name }}</h6>
                        </div>
                        <div class="col-md-9 text-right mb-2">
                          <v-btn small class="btn mr-2" @click="editNote(note)"><i class="fa fa-edit"></i> Edit </v-btn>
                          <v-btn v-if="note.requires_follow_up" small class="btn mr-2" @click="addNoteFollowup(note.id)"><i class="fa fa-plus"></i> Add note follow up </v-btn>
                          <v-btn v-if="note.requires_follow_up" small class="btn mr-2" @click="noteMarked(note)"><i class="fas fa-check"></i> Mark note </v-btn>

                          <v-btn small class="btn mr-2" @click.prevent="deleteNote(note.id,index)"> <i class="fa fa-trash"></i>
                            Delete
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div class="separator separator-solid"></div>
                    <div class="pt-8 pb-6">
                      <div class="d-flex align-items-center justify-content-between mb-1">
                        <span class="font-weight-bold mr-2">Title:</span>
                        <a href="#" class="font-weight-regular text-hover-primary text-hover-primary">  {{ note.name }}</a>
                      </div>
                      <div class="d-flex align-items-center justify-content-between mb-1">
                        <span class="font-weight-bold mr-2">Priority:</span>
                        <span class="font-weight-regular badge badge-primary" v-if="note.priority != 'urgent'">{{note.priority_text}}</span>
                        <span class="font-weight-regular badge badge-danger" v-else>  {{note.priority_text}}</span>
                      </div>
<!--                      <div class="d-flex align-items-center justify-content-between mb-1">-->
<!--                        <span class="font-weight-bold mr-2">Is Admin:</span>-->
<!--                        <span class="badge badge-primary">{{note.is_admin_only ? 'Yes' : 'No'}}</span>-->
<!--                      </div>-->
                      <div class="d-flex align-items-center justify-content-between mb-1">
                        <span class="font-weight-bold mr-2">Added by:</span>
                        <span class="font-weight-regular text-hover-primary"> {{note.addedBy ? note.addedBy : 'N/A'}}</span>
                      </div>

                      <div class="d-flex align-items-center justify-content-between mb-1" v-if="note.note_attachments && note.note_attachments.length > 0">
                        <div class="font-weight-bold mr-2">
                          Attachments
                        </div>
                        <span class="mx-1" v-for="(attachment, index) in note.note_attachments" :key="index">
                            <a v-if="attachment && attachment.file_path"
                               :href="attachment.file_path.thumb"
                               target="_blank"
                               class=" mt-2 font-13 font-weight-bold"
                            >
                                <v-icon class="mx-1" small>fas fa-file</v-icon>
                                File
                            </a>

                            <a v-else
                               :href="attachment.url"
                               target="_blank"
                               class="font-13 mt-2 font-weight-bold"
                            >
                                <v-icon class="ml-1" small>fas fa-link</v-icon>
                                Link
                            </a>
                        </span>
                      </div>
                    </div>
                    <v-row class="mt-2">
                      <v-col cols="3" v-if="note.note_followups && note.note_followups.length > 0">
                        Note Followups:-
                      </v-col>
                      <v-col cols="9" v-if="note.note_followups && note.note_followups.length > 0">
                        <v-expansion-panels>
                          <v-expansion-panel
                              v-for="(followup, index) in note.note_followups" :key="index"
                          >
                            <v-expansion-panel-header>
                              {{ followup.formatted_date }}
                              Added by: <span class="ml-1 font-italic">{{ followup.addedBy ? followup.addedBy : 'N/A' }} <v-icon class="mx-2 cursor-pointer" x-small color="red" @click="deleteFollowup(followup.id)">fas fa-trash</v-icon></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content class="ml-6 text-justify" v-html="followup.remarks">
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                      <v-col cols="12">
                        <div class="pt-8 pb-6">
                          <div class="d-flex align-items-center justify-content-between mb-1">
                            <span class="font-weight-bold mr-2">Follow up required :</span>
                            <span class="badge badge-primary">{{note.requires_follow_up ? 'Yes' : 'No'}}</span>
                          </div>
<!--                          <div class="d-flex align-items-center justify-content-between mb-1">-->
<!--                            <span class="font-weight-bold mr-2">Follow up required :</span>-->
<!--                            <span class="badge badge-primary">{{note.is_admin_only ? 'Yes' : 'No'}}</span>-->
<!--                          </div>-->
                          <div class="d-flex align-items-center justify-content-between mb-1">
                            <span class="font-weight-bold mr-2">Follow up date :</span>
                            <span class="badge badge-primary">{{note.formatted_followup_required_date ? note.formatted_followup_required_date : 'N/A'}}</span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <manage-note ref="create-and-update" @refresh="getAllNotes"></manage-note>
    <add-note-followup ref="add-note-followup" @refresh="getAllNotes"></add-note-followup>
    <v-overlay :value="isDeleting">
      <v-progress-circular
          indeterminate
          color="dark"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import NoteService from "@/services/note/NoteService";
import ManageNote from "@/view/pages/view/practical-exam/exam-day/widget/ManageNote";
import AddNoteFollowup from "@/view/pages/view/candidate/summary/partials/notes/AddNoteFollowup";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
const examDay=new ExamDayService();
const note=new NoteService();
export default {
  name: "ShowNotes",
  components:{ManageNote,AddNoteFollowup},
  data() {
    return {
      notes: [],
      isDeleting:false,
      examDayDetail:{},
    }
  },
  methods:{
    getAllNotes(){

          note
            .getAllExamDayNote(this.examDayId)
            .then(response => {
              this.notes = response.data.notes;
            });

    },
    editNote(note) {
      this.$refs['create-and-update'].editNote(note);
    },
    addNoteFollowup(id) {
      this.$refs['add-note-followup'].addNoteFollowup(id);
    },

    deleteNote(id) {

      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {

          if (confirm) {
            this.isDeleting=true;
            note
                .delete(id)
                .then((response) => {
                  this.$emit('refresh');
                  this.$snotify.success("Note deleted");
                  this.getAllNotes();

                })
                .catch((err) => {

                  this.$snotify.error("Oops something went wrong");
                }).finally(() => {
              this.isDeleting = false;
            });
          }
        },
      });
    },
    noteMarked(item) {
      this.isDeleting = true;
      note
          .noteMarked(item.id, item)
          .then((response) => {
              this.$snotify.success("Note Marked Successfully ");
              this.getAllNotes();
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.isDeleting = false;
          });
    },
    getExamDayInfo(){
      examDay
          .show(this.examDayId)
          .then((response) => {
            this.examDayDetail = response.data.examDay;

          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
  },
  mounted() {
    this.getAllNotes();
    this.getExamDayInfo();
  },
  computed:{
    examDayId() {
      return this.$route.params.examDayId;
    },
  }
}
</script>